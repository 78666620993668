const userPreferenceMap = {};

function setAppSetting(appName, appSetting) {
  userPreferenceMap[appName] = appSetting;
}

function getAppSetting(appName) {
  return userPreferenceMap[appName];
}

function setValueObj(appName, preferenceKey, valueObj) {
  userPreferenceMap[appName] = userPreferenceMap[appName] || {};
  userPreferenceMap[appName][preferenceKey] = valueObj;
}

function getValueObj(appName, preferenceKey) {
  return userPreferenceMap[appName] && userPreferenceMap[appName][preferenceKey];
}

const preferenceManager = {
  setAppSetting,
  getAppSetting,
  setValueObj,
  getValueObj
};

export default preferenceManager;
