const appearanceMap = new Map();
const DEFAULT_THEME_ID = 'sap_horizon';
const DEFAULT_THEME_NAME = 'default_theme';
const THEME_LIST_NAME = 'theme_list';
const DEBUG_MODE = 'debug';
const THEME_ID = 'theme_id';
const DIRECTION = 'direction';
const COMPACT = 'compact';

// fiori theme list https://sap.github.io/ui5-webcomponents/playground/advanced/configuration/
// 'sap_fiori_3',
// 'sap_fiori_3_dark',
// 'sap_fiori_3_hcb',
// 'sap_fiori_3_hcw',
// 'sap_belize',
// 'sap_belize_hcb',
// 'sap_belize_hcw',
// 'sap_horizon',
const DEFAULT_THEME_LIST = [
  {
    name: 'sap_horizon',
    displayName: 'SAP Morning Horizon',
    themeId: 'sap_horizon',
    icon: '',
    style: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #758ca4',
      boxShadow: 'inset 0 0 2px #758ca4',
    },
    order: '10',
  },
  {
    name: 'sap_horizon_dark',
    displayName: 'SAP Evening Horizon',
    themeId: 'sap_horizon_dark',
    icon: '',
    style: {
      backgroundColor: 'rgb(29, 45, 63)',
      border: '1px solid #1D2D3E',
      boxShadow: 'inset 0 0 2px #a9b4be',
    },
    order: '20',
  },
  {
    name: 'sap_horizon_hcw',
    displayName: 'SAP High Contrast White (SAP Horizon)',
    themeId: 'sap_horizon_hcw',
    icon: '',
    style: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #000',
    },
    order: '30',
  },
  {
    name: 'sap_horizon_hcb',
    displayName: 'SAP High Contrast Black (SAP Horizon)',
    themeId: 'sap_horizon_hcb',
    icon: '',
    style: {
      backgroundColor: 'rgb(0, 0, 0)',
      border: '1px solid #FFF',
    },
    order: '40',
  },
  {
    name: 'sap_belize',
    displayName: 'SAP Belize',
    themeId: 'sap_belize',
    icon: '',
    style: {},
    order: '50',
  },
  {
    name: 'sap_belize_hcb',
    displayName: 'SAP High Contrast Black (SAP Belize)',
    themeId: 'sap_belize_hcb',
    icon: '',
    style: {},
    order: '60',
  },
  {
    name: 'sap_belize_hcw',
    displayName: 'SAP High Contrast White (SAP Belize)',
    themeId: 'sap_belize_hcw',
    icon: '',
    style: {},
    order: '70',
  },
  {
    name: 'sap_fiori_3',
    displayName: 'SAP Quartz Light',
    themeId: 'sap_fiori_3',
    icon: '',
    style: {},
    order: '80',
  },
  {
    name: 'sap_fiori_3_dark',
    displayName: 'SAP Quartz Dark',
    themeId: 'sap_fiori_3_dark',
    icon: '',
    style: {},
    order: '90',
  },
  {
    name: 'sap_fiori_3_hcb',
    displayName: 'SAP High Contrast Black (SAP Quartz)',
    themeId: 'sap_fiori_3_hcb',
    icon: '',
    style: {},
    order: '100',
  },
  {
    name: 'sap_fiori_3_hcw',
    displayName: 'SAP High Contrast White (SAP Quartz)',
    themeId: 'sap_fiori_3_hcw',
    icon: '',
    style: {},
    order: '110',
  },
];

appearanceMap.set(DEBUG_MODE, false);

const getThemeId = () => {
  const isDebug = appearanceMap.get(DEBUG_MODE);

  if (isDebug) {
    if (appearanceMap.get(THEME_ID)) {
      console.log(`getTheme: key is ${THEME_ID}, value is ${appearanceMap.get(THEME_ID)}`);
    } else {
      console.log(`getTheme: key ${THEME_ID} is missing`);
    }
  }

  return appearanceMap.get(THEME_ID) || getDefaultThemeId();
};

const setThemeId = theme => {
  const isDebug = appearanceMap.get(DEBUG_MODE);

  if (isDebug) {
    if (appearanceMap.get(THEME_ID)) {
      console.log(`setTheme: key is ${THEME_ID}, value is ${theme}`);
    } else {
      console.log(`setTheme: key ${THEME_ID} is missing`);
    }
  }

  if (!getThemeList().some(ele => ele.themeId === theme)) {
    const defaultTheme = getDefaultThemeId();
    console.error(
      `setTheme error: key is ${THEME_ID}, value is ${theme}. ${theme} is not in theme list, fallback to ${defaultTheme} theme`,
    );
    appearanceMap.set(THEME_ID, defaultTheme);
    return;
  }

  appearanceMap.set(THEME_ID, theme);
};

const getAppearance = key => {
  return appearanceMap.get(key);
};

const setAppearance = (key, value) => {
  appearanceMap.set(key, value);
};

const getDirection = () => {
  return appearanceMap.get(DIRECTION);
};

const setDirection = value => {
  appearanceMap.set(DIRECTION, value);
};

const getCompact = () => {
  return appearanceMap.get(COMPACT);
};

const setCompact = value => {
  appearanceMap.set(COMPACT, value);
};

const getThemeList = () => {
  return appearanceMap.get(THEME_LIST_NAME) || DEFAULT_THEME_LIST;
};

const setThemeList = list => {
  appearanceMap.set(THEME_LIST_NAME, list);
};

const getDefaultThemeList = () => {
  return DEFAULT_THEME_LIST;
};

const getDefaultThemeId = () => {
  return appearanceMap.get(DEFAULT_THEME_NAME) || DEFAULT_THEME_ID;
};

const setDefaultThemeId = theme => {
  appearanceMap.set(DEFAULT_THEME_NAME, theme);
};

const setDebug = debug => {
  appearanceMap.set(DEBUG_MODE, debug);
};

export default {
  getThemeId,
  setThemeId,
  getDirection,
  setDirection,
  getCompact,
  setCompact,
  getDefaultThemeId,
  setDefaultThemeId,
  setThemeList,
  getThemeList,
  getDefaultThemeList,
  getAppearance,
  setAppearance,
  setDebug,
};
