const crypto = window.crypto || window.msCrypto;
const array = new Uint32Array(1);

export const getRandom = (digit = 5) => {
  return (crypto.getRandomValues(array)[0] + '').slice(0, 5);
};

export const generateUniqueDict = () => {
  const dict = new Map();
  function getItem(key) {
    if (!dict[key]) {
      dict[key] = {};
    }
    return dict[key];
  }

  function findFuncInConfiguration(key, funcName) {
    if (!key || key === '') {
      throw new Error('Application name should not be empty or null, key =>' + key);
    }
    if (!funcName || funcName === '') {
      throw new Error('Function name should not be empty or null, funcName =>' + funcName);
    }
    const app = dict[key];
    const windowObjHook = window[funcName + key.slice(0, 1).toUpperCase() + key.slice(1)];
    if (app && app.configuration && typeof app.configuration[funcName] === 'function') {
      const renderFunc = app.configuration[funcName];
      return renderFunc;
    } else if (windowObjHook) {
      return windowObjHook;
    }
    console.warn(`Not able to find ${funcName} functon for app => ${key}`);
  }
  return {
    dict,
    getItem,
    findFuncInConfiguration,
  };
};

export const formatNumber = (tobeForamtNumber, decimalPlace, decimalSymbol) => {
  let number = tobeForamtNumber;
  if (number === null || number === undefined) {
    return '';
  }
  number = parseFloat(number);
  if (isNaN(number)) {
    return '';
  }
  number = parseFloat(number.toFixed(decimalPlace));
  if (decimalSymbol === '.') {
    number = number.toLocaleString('en');
  } else {
    number = number.toLocaleString('de');
  }
  const decimalIndex = number.indexOf(decimalSymbol);
  if (decimalPlace > 0) {
    if (decimalIndex >= 0) {
      const decimalLength = number.length - decimalIndex - 1;
      const difference = decimalPlace - decimalLength;
      if (difference > 0) {
        number = number.padEnd(number.length + difference, '0');
      }
    } else {
      number += decimalSymbol;
      number = number.padEnd(number.length + decimalPlace, '0');
    }
  }
  return number;
};

export const mockRequest = ({ result = null, error = null, rate = 1 }) => {
  return new Promise((resolve, reject) => {
    if (Math.random() <= rate) {
      resolve({
        data: result,
      });
    } else {
      reject(error);
    }
  });
};

export const loadScript = (dom, assetName, host, name, filePath, hash, callback) => {
  const scriptId = `${assetName}-script-${name.toLowerCase()}`;
  if (dom.getElementById(scriptId)) {
    return;
  }

  const script = dom.createElement('script');
  script.id = scriptId;
  script.crossOrigin = 'anonymous';
  script.src = `${host}${filePath}?random=${getRandom()}`;
  if (hash) {
    script.integrity = hash;
  }
  script.onload = callback;
  dom.head.appendChild(script);
};

export const loadCSS = (dom, assetName, host, name, filePath, hash, callback) => {
  const styleId = `${assetName}-style-${name.toLowerCase()}`;
  if (dom.getElementById(styleId)) {
    return;
  }

  const cssLink = dom.createElement('link');
  cssLink.id = styleId;
  cssLink.rel = 'stylesheet';
  cssLink.type = 'text/css';
  cssLink.crossOrigin = 'anonymous';
  cssLink.href = `${host}${filePath}?random=${getRandom()}`;
  if (hash) {
    cssLink.integrity = hash;
  }
  cssLink.onload = callback;
  dom.head.appendChild(cssLink);
};
