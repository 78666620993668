import { generateUniqueDict } from './utils';
const uniqueDict = generateUniqueDict();
const Suffix = '_Application';
const registerApp = (appName, configuration) => {
  const application = uniqueDict.getItem(appName + Suffix);
  application.configuration = configuration;
};
const renderApp = (appName, ...rest) => {
  const func = uniqueDict.findFuncInConfiguration(appName + Suffix, 'render');
  if (func) {
    func.apply(null, rest);
  }
};
const unmountApp = (appName, ...rest) => {
  const func = uniqueDict.findFuncInConfiguration(appName + Suffix, 'unmount');
  if (func) {
    func.apply(null, rest);
  }
};
const getComponent = appName => {
  const app = uniqueDict.getItem(appName + Suffix);
  return app && app.configuration && app.configuration.component;
};
export default {
  get: getComponent,
  getComponent,
  register: registerApp,
  registerApp,
  render: renderApp,
  renderApp,
  unmount: unmountApp,
  unmountApp,
};
