const cfgMap = new Map();
const CSRF_TOKEN = '';
const DEBUG_MODE = 'debug';
const FEATURE_TOGGLE = 'feature-toggle-store';
cfgMap.set(DEBUG_MODE, false);

const getConfig = key => {
  const isDebug = cfgMap.get(DEBUG_MODE);

  if (isDebug) {
    if (cfgMap.get(key)) {
      console.log(`getConfig: key is ${key}, value is ${cfgMap.get(key)}`);
    } else {
      console.log(`getConfig: ${key} is missing`);
    }
  }

  return cfgMap.get(key);
};

const addConfig = (key, value) => {
  const isDebug = cfgMap.get(DEBUG_MODE);

  if (isDebug) {
    if (cfgMap.get(key)) {
      console.log(`addConfig: update key is ${key}, value is ${value}`);
    } else {
      console.log(`addConfig: new key is ${key}, value is ${value}`);
    }
  }
  cfgMap.set(key, value);
};

const getCsrfToken = () => {
  return getConfig(CSRF_TOKEN);
};

const setCsrfToken = token => {
  addConfig(CSRF_TOKEN, token);
};

const setDebug = debug => {
  addConfig(DEBUG_MODE, debug);
};

const getFeatureToggle = (...args) => {
  const featureToggles = getConfig(FEATURE_TOGGLE) || {};
  if (args.length === 0) {
    // assume you want all
    return {
      ...featureToggles,
    };
  } else if (args.length === 1) {
    // assume it's a string array or a standalone string
    const arg = args[0];
    if (Array.isArray(arg) && arg.length > 0) {
      const values = {};
      arg.forEach(key => {
        values[key] = featureToggles[key];
      });
      return values;
    } else {
      const stringKey = String(arg);
      return featureToggles[stringKey];
    }
  } else {
    // if (args.length > 1)
    // assume paramters are all string keys
    const values = {};
    const total = args.length;
    for (let i = 0; i < total; i++) {
      values[args[i]] = featureToggles[args[i]];
    }
    return values;
  }
};
const updateFeatureToggleValues = (arrays, featureToggles) => {
  /**
    {
      feature: "OME.5482.Activity.Summary"
      isDelete: false
      isEnable: true
    }
   */
  arrays.forEach(item => {
    const hasEnable = item.hasOwnProperty('isEnable');
    const key = item.feature || item.key;
    let value = false;
    if (hasEnable) {
      value = item.isEnable;
    } else {
      value = item.value;
    }
    const hasDelete = item.hasOwnProperty('isDelete');
    if (hasDelete) {
      const isDelete = item.isDelete;
      if (isDelete === true) {
        value = true;
      }
    }
    featureToggles[key] = value;
  });
};

const updateFeatureToggle = (...args) => {
  // different ways // otherwise not valid args
  if (args.length === 1) {
    const featureToggles = { ...getConfig(FEATURE_TOGGLE) };
    // assume you pass an object to update or an array with key/value
    const updateObject = args[0];
    if (Array.isArray(updateObject)) {
      /**
        updateFeatureToggle([{
          feature: "OME.5482.Activity.Summary"
          isDelete: false
          isEnable: true
        }]) 
      */
      updateFeatureToggleValues(updateObject, featureToggles);
    } else if (typeof updateObject === 'object') {
      /**
        updateFeatureToggle({
          "OME.5482.Activity.Summary": {
            feature: "OME.5482.Activity.Summary"
            isDelete: false
            isEnable: true
          }
        }) 
       */
      const arrays = Object.keys(updateObject).map(key => ({
        ...updateObject[key],
        feature: updateObject[key].feature || key, 
      }));
      updateFeatureToggleValues(arrays, featureToggles);
    }
    addConfig(FEATURE_TOGGLE, featureToggles);
  } else if (args.length >= 2) {
    // updateFeatureToggle(key, value)
    const featureToggles = { ...getConfig(FEATURE_TOGGLE) };
    const key = args[0];
    const value = args[1];
    featureToggles[key] = value;
    addConfig(FEATURE_TOGGLE, featureToggles);
  }
};

const getLanguage = () => {
  return getConfig('ui-language');
};

const setLanguage = language => {
  addConfig('ui-language', language);
};

const configManager = {
  addConfig,
  getConfig,
  getCsrfToken,
  setCsrfToken,
  setDebug,
  getFeatureToggle,
  updateFeatureToggle,
  getLanguage,
  setLanguage,
};

export default configManager;
